import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {DatePipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {IconBadgeComponent} from "../../../shared/elements/icon-badge/icon-badge.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {UserService} from "../../../shared/services/user.service";
import {LinkComponent} from "../../../shared/elements/link/link.component";
import {RouterService} from "../../../shared/services/router.service";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {Subscription} from "rxjs";
import {ApiService} from "../../../shared/services/api.service";
import {ErrorService} from "../../../shared/services/error.service";
import {LanguageService} from "../../../shared/services/language.service";
import {NotificationComponent} from "../../../shared/elements/notification/notification.component";
import {TYPE} from "../../../shared/types/types";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {WarningComponent} from "../../../shared/elements/warning/warning.component";
import {DividerDateComponent} from "../../../shared/elements/divider-date/divider-date.component";
import {LoadingIndicatorComponent} from "../loading-indicator/loading-indicator.component";
import {FormDropdownComponent} from "../../../shared/elements/form-dropdown/form-dropdown.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {arrayValidator} from "../../../shared/validators/array.directive";
import {DialogProfileLanguageComponent} from "../../profile/dialog-profile-language/dialog-profile-language.component";
import {DialogProfilePushComponent} from "../../profile/dialog-profile-push/dialog-profile-push.component";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {AvatarComponent} from "../../../shared/elements/avatar/avatar.component";

@Component({
  selector: 'app-bar-top',
  standalone: true,
  imports: [
    NgIf,
    IconBadgeComponent,
    TranslatePipe,
    NgOptimizedImage,
    LinkComponent,
    FormButtonComponent,
    NotificationComponent,
    NgForOf,
    HeadlineComponent,
    DropDownListModule,
    WarningComponent,
    DividerDateComponent,
    DatePipe,
    LoadingIndicatorComponent,
    FormDropdownComponent,
    DialogProfileLanguageComponent,
    DialogProfilePushComponent,
    TooltipComponent,
    AvatarComponent
  ],
  templateUrl: './bar-top.component.html',
  styleUrl: './bar-top.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BarTopComponent implements OnInit, OnDestroy {
  public activeModules: TYPE.FieldSettingsModelWithId[] = this.userService.getActiveModulesAssoc();
  public filterForm: FormGroup = this.formBuilder.group({
    module: ['', [arrayValidator(this.activeModules.concat([{id: '', name: ''}]).map(item => item.id))]]
  });
  public tab: 'notifications'|'chat'|null = null;
  public status: 'new'|'read' = 'new';
  public messages: TYPE.Notification[] = [];
  public module: 'calendar'|'channel'|'chat'|'intranet'|'mapp'|'meeting'|'survey'|'workflow'|null = null;
  public profileLanguageDialog: boolean = false;
  public profilePushDialog: boolean = false;

  private page: number = 0;
  private subscriptions: Subscription[] = [];

  public constructor(
    public userService: UserService,
    public routerService: RouterService,
    private apiService: ApiService,
    private errorService: ErrorService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder
  ) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public openPopup(type: 'language' | 'profile' | 'settings' | 'push'): void {
    // TODO: implement all the popups, maybe by service
    console.log('open popup: ' + type);
  }

  public signOut(): void {
    this.userService.resetUser();
    this.routerService.navigateTo('/auth/login');
  }

  public setTab(tab: 'notifications'|'chat'): void {
    this.tab = (tab === this.tab ? null : tab);
    this.page = 0;
    this.status = 'new';
    this.module = (this.tab === 'chat' ? 'chat' : null);
    if (this.tab) {
      this.load();
    }
  }

  public closeTab()
  {
    this.tab = null;
  }

  public setStatus(status: 'new'|'read'): void {
    this.status = status;
    this.page = 0;
    this.load();
  }

  public setRead(): void {
    this.subscriptions.push(this.apiService.setBellNotedAll().subscribe({
      next: (success) => {
        this.page = 0;
        this.load();
      }, error: (error) => {
        this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, true);
      }
    }));
  }

  public load(eventData: any = ''): void {
    // TODO implement bell load with new, read and filter
    //this.module = this.filterForm.get('module')!.value;
    this.module = (eventData) ? eventData : null;
    console.log('Tab: ' + this.tab + ', Status: ' + this.status + ', Module: ' + this.module + ', Page: ' + this.page);

    this.subscriptions.push(this.apiService.getBell(this.page, this.status, this.module).subscribe({
      next: (messages) => {
        if (this.page === 0) {
          this.messages = messages;
        } else {
          this.messages = this.messages.concat(messages);
        }
      }, error: (error) => {
        this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, true);
      }
    }));
  }
}
