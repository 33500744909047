import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {RouterService} from "../../../shared/services/router.service";
import {UserService} from "../../../shared/services/user.service";
import {NgForOf, NgIf} from "@angular/common";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";

@Component({
  selector: 'app-bar-icons',
  standalone: true,
  imports: [
    FormButtonComponent,
    NgForOf,
    NgIf,
    TooltipComponent,
    TranslatePipe
  ],
  templateUrl: './bar-icons.component.html',
  styleUrl: './bar-icons.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BarIconsComponent implements OnInit {

  public iconsAllowed: string[] = [];
  iconData = [
    { key: 'intranet', textKey: 'NEWS', icon: 'calendar_view_day', iconClass: 'text-darkblue fs-4', route: '/news' },
    { key: 'mapp', textKey: 'KNOWLEDGEBASE', icon: 'psychology', iconClass: 'text-orange fs-4', route: '/mapp' },
    { key: 'chat', textKey: 'CHAT', icon: 'chat', iconClass: 'text-aqua fs-4', route: '/chat' },
    { key: 'channel', textKey: 'COMMUNITY', icon: 'forum', iconClass: 'text-yellow fs-4', route: '/forum' },
    { key: 'meeting', textKey: 'MEETING', icon: 'videocam', iconClass: 'text-blue fs-4', route: '/meetings' },
    { key: 'calendar', textKey: 'CALENDAR', icon: 'calendar_month', iconClass: 'text-asphalt fs-4', route: '/calendar' },
    { key: 'survey', textKey: 'FEEDBACK', icon: 'cycle', iconClass: 'text-lightpurple fs-4', route: '/feedback' },
    { key: 'workflow', textKey: 'WORKFLOW', icon: 'rebase', iconClass: 'text-pink fs-4', route: '/workflow' },
    { key: 'document', textKey: 'DOCUMENTMANAGER', icon: 'folder', iconClass: 'text-brightgreen fs-4', route: '/documents' },
    { key: 'tv', textKey: 'TV', icon: 'tv', iconClass: 'text-cyan fs-4', route: '/tv' },
    { key: 'employee', textKey: 'CONTACTS', icon: 'import_contacts', iconClass: 'text-darkorange fs-4', route: '/employees' },
    { key: 'profile', textKey: 'PROFILE', icon: 'perm_contact_calendar', iconClass: 'text-purple fs-4', route: '/profile' },
    { key: 'settings_users', textKey: 'USER', icon: 'person', iconClass: 'text-darkgreen fs-4', route: '/admin/users' },
    { key: 'settings_groups', textKey: 'GROUPS', icon: 'group', iconClass: 'text-skyblue fs-4', route: '/admin/groups' },
    { key: 'general', textKey: 'SETTINGS', icon: 'settings', iconClass: 'text-red fs-4', route: '/general' },
  ];
  public iconBarVisible: boolean = false;

  constructor(
    public routerService: RouterService,
    private userService: UserService,
  ) {
  }

  public ngOnInit(): void {
    this.iconsAllowed = this.userService.getActiveModules().concat(this.userService.getAdministrativeRights());

    this.userService.iconBarVisible.subscribe((value) => {
      this.iconBarVisible = value;
    });
  }

}
