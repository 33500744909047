<ejs-dialog target=".dialogs" class="simple-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'30%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true" (close)="close()">
  <ng-template #header>
    <div class="d-flex align-items-center justify-content-center">
      <app-headline [title]="('PROFILELANGUAGE' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
      <app-tooltip [icon]="'help'" [iconClass]="'fs-5 p-1'" [text]="('PROFILELANGUAGETOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
    </div>
  </ng-template>
  <ng-template #content>
    <div class="d-flex flex-column h-100 p-3">
      <app-form-input type="search" [placeholder]="('SEARCHFORLANGUAGE' | translate)" [iconFront]="'search'" [iconBack]="'close'" [iconClickType]="'back'" [fCN]="'searchString'" [fG]="profileLanguageForm"></app-form-input>

      <!-- Scrollable content-->
      <div class="custom-scrollbar overflow-y-auto h-100 e-listview">
        <ng-container *ngFor="let language of languages">
          <div class="languageItem e-listview-item d-flex align-items-stretch cursor-pointer" (click)="selectLanguage(language)">
            <div class="d-flex align-items-center justify-content-center p-2">
              <app-avatar [image]="'/assets/images/flags/' + language + '.svg'" [name]="language.toUpperCase() | translate" [type]="'xxs'" [icon]="'translate'" [border]="false"></app-avatar>
            </div>
            <div class="flex-grow-1 d-flex ps-2 align-items-center">
              <app-headline [title]="language.toUpperCase() | translate" [type]="'h6'" [class]="'mb-0 text-muted fs-small'"></app-headline>
            </div>
            <div class="d-flex align-items-center justify-content-center px-2">
              <span class="mts-icon fs-5" *ngIf="activeLanguage === language">check</span><span class="visually-hidden">{{ 'SRSELECT' | translate }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="d-flex align-items-center justify-content-center p-2">
      <app-form-button [text]="'SAVE' | translate" (click)="save()" [cssClass]="'btn btn-primary mx-auto w-50'"></app-form-button>
    </div>
  </ng-template>
</ejs-dialog>
