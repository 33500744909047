<div class="icon-list-container position-relative">
  <div class="icon-list position-fixed top-0 end-0 p-m">
    <app-tooltip [text]="('SRNEWALERTS' | translate)" [opensOn]="'Hover'" [position]="'BottomCenter'">
      <app-icon-badge (click)="setTab('notifications')" [style]="'barTop'" [icon]="'notifications'" [alert]="true" [screenReaderText]="'SRNEWALERTS' | translate"></app-icon-badge>
    </app-tooltip>
    <app-tooltip [text]="('SRNEWMASSAGES' | translate)" [opensOn]="'Hover'" [position]="'BottomCenter'">
      <app-icon-badge (click)="setTab('chat')" [style]="'barTop'" [icon]="'chat_bubble'" [alert]="true" [screenReaderText]="'SRNEWMASSAGES' | translate"></app-icon-badge>
    </app-tooltip>

    <div class="dropdown d-inline p-1">
      <button type="button" class="btn btn-link p-0 text-muted dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
        <app-avatar [image]="userService.getUser()!.avatar" [name]="userService.getUser()!.first_name + ' ' + userService.getUser()!.last_name" [type]="'medium'"></app-avatar>
      </button>
      <ul class="dropdown-menu dropdown-menu-end">
        <li><app-link (click)="profileLanguageDialog = true;" [title]="('LANGUAGE' | translate)" [style]="'dropdown'">{{ ('LANGUAGE' | translate) }}</app-link></li>
        <li><hr class="dropdown-divider"></li>
        <li><app-link (click)="routerService.navigateTo('/profile')" [title]="('PROFILE' | translate)" [style]="'dropdown'">{{ ('PROFILE' | translate) }}</app-link></li>
        <li><app-link (click)="profilePushDialog = true;" [title]="('PUSHMESSAGESETTINGS' | translate)" [style]="'dropdown'">{{ ('PUSHMESSAGESETTINGS' | translate) }}</app-link></li>
        <li><app-link (click)="userService.toggleIconBar()" [title]="('BARICONSVISIBILITY' | translate)" [style]="'dropdown'">{{ ('BARICONSVISIBILITY' | translate) }}</app-link></li>
        <li><hr class="dropdown-divider"></li>
        <li><app-link (click)="signOut()" [title]="('SIGNOUT' | translate)" [style]="'dropdown'">{{ ('SIGNOUT' | translate) }}</app-link></li>
      </ul>
    </div>

    <div *ngIf="tab" class="notification-window shadow-lg p-3 d-flex flex-column">
      <div *ngIf="tab === 'notifications'" class="d-flex justify-content-end align-items-center mb-2">
        <app-form-button [style]="'btn'" (click)="closeTab()" [cssClass]="'btn-close'"></app-form-button>
      </div>

      <div *ngIf="tab === 'chat'" class="d-flex justify-content-between align-items-center mb-2">
        <app-headline [title]="('CHATNOTIFICATIONS' | translate)" [type]="'h6'"></app-headline>
        <app-form-button [style]="'btn'" (click)="closeTab()" [cssClass]="'btn-close'"></app-form-button>
      </div>

      <div class="d-flex" *ngIf="tab === 'notifications'">
        <app-form-button [text]="'NEW' | translate" [cssClass]="status === 'new' ? 'e-primary w-100 mx-1' : 'e-outline w-100 mx-1'" (click)="setStatus('new')"></app-form-button>
        <app-form-button [text]="'READ' | translate" [cssClass]="status === 'read' ? 'e-primary w-100 mx-1' : 'e-outline w-100 mx-1'" (click)="setStatus('read')"></app-form-button>
      </div>

      <div *ngIf="tab === 'notifications'" class="d-flex justify-content-between align-items-center mt-3 pb-1">
        <app-headline [type]="'h6'" [title]="('NOTIFICATIONS' | translate)"></app-headline>
        <div class="dropdown" *ngIf="tab === 'notifications'">
          <div id="notifications-menu" data-bs-toggle="dropdown" aria-expanded="false">
            <app-icon-badge [style]="'notification'" [icon]="'more_vert'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
          </div>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notifications-menu">
            <li><app-link (click)="setRead()" [title]="('BELLSETALLREAD' | translate)" [style]="'dropdown'">{{ ('BELLSETALLREAD' | translate) }}</app-link></li>
          </ul>
        </div>
      </div>

      <app-warning [message]="'WARNINGNOTIFICATIONS' | translate" [linkText]="'WARNINGNOTIFICATIONSLINK' | translate" (click)="openPopup('push')"></app-warning>

      <div class="d-flex justify-content-end mb-3"  *ngIf="tab === 'notifications'">
        <div class="col-md-6">
          <app-form-dropdown [fG]="filterForm" [fCN]="'module'" [dataSource]="activeModules" [placeholder]="'SELECTFILTER' | translate" [icon]="'filter_list'" [cssClass]="'small-filter-dropdown'" [withEmpty]="true" (valueChange)="load($event)"></app-form-dropdown>
        </div>
      </div>

      <div class="custom-scrollbar">
        @defer (on immediate) {
          <ng-container *ngFor="let item of messages; let i = index">
            <ng-container *ngIf="i === 0 || (item.created_at | date : 'shortDate') !== (messages[(i - 1)].created_at | date : 'shortDate')">
              <app-divider-date [date]="item.created_at"></app-divider-date>
            </ng-container>
            <app-notification [notification]="item"></app-notification>
          </ng-container>
        } @loading (after 100ms) {
          <app-loading-indicator></app-loading-indicator>
        }
      </div>
    </div>
  </div>
</div>

<div class="home-icon-container position-relative" *ngIf="routerService.getCurrentTarget() !== '/home'">
  <div class="home-icon position-fixed top-0 start-0 p-0">
    <app-tooltip [text]="('HOME' | translate)" [opensOn]="'Hover'" [position]="'RightCenter'">
      <app-form-button [style]="'btn'" [icon]="'apps'" [cssClass]="'btn btn-link p-m text-muted position-relative'" [iconClass]="'fs-3'" (click)="routerService.navigateTo('/home')"></app-form-button>
    </app-tooltip>
  </div>
</div>

<app-dialog-profile-language *ngIf="profileLanguageDialog" [(visible)]="profileLanguageDialog"></app-dialog-profile-language>
<app-dialog-profile-push *ngIf="profilePushDialog" [(visible)]="profilePushDialog"></app-dialog-profile-push>
